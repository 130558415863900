export const SET_ANALYTICS_SELECTED_PROJECT = "SET_ANALYTICS_SELECTED_PROJECT"
export const CLEAR_ALL_ANALYTICS_STATE = "CLEAR_ALL_ANALYTICS_STATE"
export const SET_ANALYTICS_REQ_PARAMS = "SET_ANALYTICS_REQ_PARAMS"
export const SET_SELECTED_COUNT_DAY = "SET_SELECTED_COUNT_DAY"
export const SET_STATISTICS_STATUS = "SET_STATISTICS_STATUS"
export const ANALYTICS_CLEAR_DATA = "ANALYTICS_CLEAR_DATA"
export const SET_ANALYTICS_STATUS = "SET_ANALYTICS_STATUS"
export const SET_PERCENT_DATA = "SET_PERCENT_DATA"
export const SET_STATISTICS = 'SET_STATISTICS'
export const SET_ANALYTICS = 'SET_ANALYTICS'
export const SET_FOLLOWERS = 'SET_FOLLOWERS'
export const SET_GROUP_ID = 'SET_GROUP_ID'
export const SET_SPOTIFY = 'SET_SPOTIFY'
