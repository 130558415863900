import Wrapper from '../util'
import Http from '../http.init'

class BaseAuthService {
  constructor(entity, data, auth = false, setData = false, setToken = false) {
    this.data = data
    this.entity = entity + '?verify_id=' + getVerifyId();
    this.auth = auth
    this.setToken = setToken
    this.setData = setData
    this.wrapper = new Wrapper
  }

  init() {
    return new Promise((resolve, reject) => {
      this.request({auth: this.auth}).post(this.entity, this.data, {headers: {accept: 'application/x.arimo.v1+json'}})
        .then(response => {
          this.wrapper.setWrapper(response).then(() => {
            if (this.setData === true) {
              _setAuthData(response.data)
            }
            if (this.setToken === true) {
              _setToken(response.data)
            }
            return resolve(response.data.data)
          }).catch((error) => {
            return reject(error)
          })
        }).catch(error => {
        reject(error)
      })
    })
  }

  request(status = {auth: false, setData: this.setData}) {
    return new Http(status)
  }
}

export function makeLogin(data) {
  return new BaseAuthService('auth/login', data, false, true, true).init()
}

export function makeRegister(data) {
  return new BaseAuthService('auth/register', data, false, true, true).init()
}

export function makeRecovery({email}) {
  return new BaseAuthService('auth/password_reset/', {email}).init()
}

export function makeLogout() {
  return new BaseAuthService('auth/logout', {token: getRefreshToken()}).init()
}

export function confirmPassword({token, password}) {
  return new BaseAuthService('auth/password_reset/confirm/', {token, password}).init()
}

export function isValidateToken({token}) {
  return new BaseAuthService('auth/password_reset/validate_token/', {token}).init()
}

export function refreshTokens() {
  return new BaseAuthService('auth/refresh', {token: getRefreshToken()}, false, false, true).init()
}

export function _setAuthData(response) {
  localStorage.setItem('userData', JSON.stringify({
    ...response.data.me,
    verify_id: response.data.me.id,
    me_id: null,
    role: response.data.me.role.name
  }))
}

export function _setToken(response) {
  localStorage.setItem('refreshToken', response.data.token)
  localStorage.setItem('accessToken', response.data.token)
  localStorage.setItem('expires_in', accessTokenExpired(response.data.expires_in))
}

export function resetAuthData() {
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('expires_in')
  localStorage.removeItem('verify_id')
  localStorage.removeItem('role')
  localStorage.removeItem('my_id')
  localStorage.removeItem('userData')
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken') || ''
}

export function isAccessTokenExpired() {
  const accessTokenExpDate = localStorage.getItem('expires_in') - 1800
  const nowTime = Math.floor(new Date().getTime() / 1000)

  return accessTokenExpDate <= nowTime
}

export function accessTokenExpired(expires_in) {
  return expires_in + Math.floor(new Date().getTime() / 1000)
}

export function getAccessToken() {
  return localStorage.getItem('accessToken') || ''
}

export function getVerifyId() {
  try {
    let userData = JSON.parse(localStorage.getItem('userData'))
    return userData.verify_id
  } catch (e) {
    return ''
  }
}

export function getUserData() {
  try {
    return JSON.parse(localStorage.getItem('userData')) || {}
  } catch (e) {
    return {}
  }
}
