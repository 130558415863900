import Http from './http.init'
import Wrapper from "./util";
import {getVerifyId} from "./auth/auth.service";

const wrapper = new Wrapper;
let verify_id = '?verify_id=' + getVerifyId();

export class BaseService {
  constructor() {
    this.wrapper = new Wrapper;
  }

  static get entity() {
    throw new Error('"entity" getter not defined')
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(status = {auth: true}) {
    return new Http(status)
  }

  static index(parameters = {}, isShowNotification = true) {
    const params = {
      ...parameters,
    }
    verify_id = '?verify_id=' + getVerifyId();
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).get(`${this.entity}${verify_id}`, {params}).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          return resolve(response.data)
        }).catch(() => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('getList ' + this.entity, error))
      })
    })
  }

  static getById(id, parameters = {}, isShowNotification = true) {
    const params = {
      ...parameters,
    }
    verify_id = '?verify_id=' + getVerifyId();
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).get(`${this.entity}/${id}${verify_id}`, {params}).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          return resolve(response.data)
        }).catch(() => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('getById ' + this.entity, error))
      })
    })
  }

  static create(data = window.required(), isShowNotification = true) {
    verify_id = '?verify_id=' + getVerifyId();
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).post(`${this.entity}${verify_id}`, data).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          return resolve(response.data)
        }).catch(() => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('create ' + this.entity, error))
      })
    })
  }

  static update(data, id, isShowNotification = true) {
    verify_id = '?verify_id=' + getVerifyId();
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).put(`${this.entity}/${id}${verify_id}`, data).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          return resolve(response.data)
        }).catch(() => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('update ' + this.entity, error))
      })
    })
  }

  static remove(id = window.required(), parameters = {}, isShowNotification = true) {
    const params = {
      ...parameters,
    }
    verify_id = '?verify_id=' + getVerifyId();
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).delete(`${this.entity}/${id}${verify_id}`, {params}).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          return resolve(response.data)
        }).catch(() => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('remove ' + this.entity, error))
      })
    })
  }
}
