import * as allConst from "../../../constants/eventDetail/tabCosts";

const initialState = {
  reqParams: {
    includes: "type",
    fields: "type.id, type.name",
    orderBy: "created_at"
  },
  types: [],
  params: {
    cost_id: null,
    isOpenModal: false,
    isCost: true,
    template_id: null,
    isAddTemplate: false
  },
  cost: {},
  costs_sum: 0,
  costs: [],
  templates: [],
  template: {},
};

const eventCostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_COSTS_FIELD:
      let costs = [].concat(state.costs);
      costs[action.id][action.field] = action.payload;
      return {
        ...state,
        costs: costs
      }
    case allConst.SET_COSTS_VALUE:
      let cost = [].concat(state.costs);
      cost[action.field] = action.payload;
      return {
        ...state,
        costs: cost
      }
    case allConst.SET_COST_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventCostsReducer;
