import * as allConst from "../../../constants/eventDetail/tabContacts";

const initialState = {
  reqParams: {
    include: "type",
    fields: "",
    limit: 10,
    page: 1,
    orderBy: "event_contact.id"
  },
  meta: {pagination:{}},
  contacts: [],
  params: {
    isOpenModal: false,
    contact_id: null,
  },
  contact: {
    position: ''
  },
};

const eventContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_CONTACTS_FIELD:
      let contacts = [].concat(state.contacts);
      contacts[action.id][action.field] = action.payload;
      return {
        ...state,
        contacts: contacts
      }
    case allConst.SET_CONTACTS_VALUE:
      let contact = [].concat(state.contacts);
      contact[action.field] = action.payload;
      return {
        ...state,
        contacts: contact
      }
    case allConst.SET_CONTACT_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    case allConst.UNSET_CONTACT_FIELD: {
      return {
        ...state,
        contact: {
          position: ''
        },
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventContactsReducer;
