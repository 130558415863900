export const SET_SCHEDULES = 'SET_SCHEDULES'
export const SET_SCHEDULE = 'SET_SCHEDULE'
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE'
export const SET_SCHEDULE_FIELD = 'SET_SCHEDULE_FIELD'
export const SET_SCHEDULE_VALUE = 'SET_SCHEDULE_VALUE'
export const SET_SCHEDULABLE_FIELD = 'SET_SCHEDULABLE_FIELD'
export const SET_SCHEDULABLE_PASSENGERS_FIELD = 'SET_SCHEDULABLE_PASSENGERS_FIELD'
export const SET_SCHEDULABLE_PASSENGERS = 'SET_SCHEDULABLE_PASSENGERS'
export const DELETE_SCHEDULABLE_PASSENGERS = 'DELETE_SCHEDULABLE_PASSENGERS'
export const ADD_SCHEDULABLE_PASSENGERS = 'ADD_SCHEDULABLE_PASSENGERS'
