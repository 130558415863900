import * as allConst from "../../constants/users";

const initialState = {
  list: [],
  data:{},
  meta: {
    pagination:{}
  },
  requestParams: {
    "page": 1,
    "search":"",
    "includes" : "role",
    "fields" : "role.name, role.description",
    "orderBy": "created_at",
    "limit": 20
  },
}

const usersReducer = (state = initialState, action) => {
  switch(action.type) {
    case allConst.SET_USERS_LIST: {
      return {
        ...state,
        list: action.payload.data,
        meta: action.payload.meta
      }
    }
    case allConst.SET_USERS_REQUEST_PARAMS: {
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      }
    }
    case allConst.SET_USER_DATA: {
      return {
        ...state,
        data: action.payload
      }
    }
    default:
      return state
  }
}

export default usersReducer;
