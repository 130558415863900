import * as allConst from "../../../constants/eventDetail/tabDocuments";

const initialState = {
  reqParams: {
    includes: "",
    fields: "",
    limit: 4,
    page: 1
  },
  meta: {pagination:{}},
  documents: [],
};

const eventDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_DOCUMENTS_FIELD:
      let documents = [].concat(state.documents);
      documents[action.id][action.field] = action.payload;
      return {
        ...state,
        documents: documents
      }
    case allConst.SET_DOCUMENTS_VALUE:
      let document = [].concat(state.documents);
      document[action.field] = action.payload;
      return {
        ...state,
        documents: document
      }
    case allConst.SET_DOCUMENT_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventDocumentsReducer;
