import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "projectsTab",
    title: "Проекты",
    type: "dropdown",
    icon: <Icon.Home size={16} />,
    children: [
      {
        id: "projects",
        title: "Список проектов",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "manager", "user"],
        navLink: "/",
      }
    ]
  },
]

export default horizontalMenuConfig
