import * as schedules from "../../constants/schedules";

const initialState = {
  reqParams: {
    includes: "passengers, schedulable, schedulable.departure, schedulable.arrival, schedulable.address",
    fields: "",
    orderBy: "-begin"
  },
  schedules: [],
  schedule_id: null,
  isOpenModal: false,
  schedule: {
    name: '',
    begin: Math.round((new Date()).getTime() / 1000),
    end: null,
    schedulable_type: 'Todo',
    schedulable: {},
    passengers: []
  }
}

const schedulesReducer = (state = initialState, action) => {
  let passengers;
  switch (action.type) {
    case schedules.SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
      }
    case schedules.SET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      }
    case schedules.CLEAR_SCHEDULE:
      return {
        ...state,
        schedule: {
          name: '',
          begin: Math.round((new Date()).getTime() / 1000),
          end: null,
          schedulable_type: 'Todo',
          schedulable: {},
          passengers: []
        }
      }
    case schedules.SET_SCHEDULE_FIELD:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          [action.field]: action.payload,
        },
      }
    case schedules.SET_SCHEDULABLE_FIELD:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          schedulable: {
            ...state.schedule.schedulable,
            [action.field]: action.payload,
          }
        },
      }
    case schedules.SET_SCHEDULABLE_PASSENGERS:
      passengers = [].concat(state.schedule.passengers);
      passengers[action.id][action.field] = action.payload;
      return {
        ...state,
        schedule: {
          ...state.schedule,
          passengers: passengers
        },
      }

    case schedules.ADD_SCHEDULABLE_PASSENGERS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          passengers: [
            ...state.schedule.passengers,
            action.payload
          ]
        }
      };
    case schedules.DELETE_SCHEDULABLE_PASSENGERS:
      passengers = state.schedule.passengers;
      passengers.splice(action.id, 1);
      return {
        ...state,
        schedule: {
          ...state.schedule,
          passengers: passengers
        }
      };
    case schedules.SET_SCHEDULE_VALUE:
      return {
        ...state,
        [action.field]: action.payload,
      }
    default:
      return state
  }
}

export default schedulesReducer;
