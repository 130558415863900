import {combineReducers} from "redux"
import {RESET_STATE} from '../constants/helper'
import analyticsReducer from './analytics'
import contactsReducer from './contacts'
import projectsReducer from './projects'
import customizer from "./customizer/"
import helperReducer from './helper'
import eventsReducer from './events'
import navbar from "./navbar/Index"
import usersReducer from './users'
import tagsReducer from "./tags"
import auth from "./auth/"

import eventDetailReducer from './eventDetail'
import eventInfoReducer from './eventDetail/tabInfo'
import schedulesReducer from "./schedules";
import eventCostsReducer from "./eventDetail/tabCosts";
import eventGuestsReducer from "./eventDetail/tabGuests";
import eventContactsReducer from "./eventDetail/tabContacts";
import eventTicketsReducer from "./eventDetail/tabTickets";
import eventFinancesReducer from "./eventDetail/tabFinances";
import eventDocumentsReducer from "./eventDetail/tabDocuments";

import tasksReducer from "./tasks";

import releasesReducer from "./releases";
import releaseDetailReducer from "./releaseDetail";
import releaseInfoReducer from './releaseDetail/tabInfo'

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  helper: helperReducer,
  projects: projectsReducer,
  analytics: analyticsReducer,
  events: eventsReducer,
  tags: tagsReducer,
  contacts: contactsReducer,
  users: usersReducer,

  eventDetail: eventDetailReducer,
  eventInfo: eventInfoReducer,
  eventCosts: eventCostsReducer,
  schedules: schedulesReducer,
  eventGuests: eventGuestsReducer,
  eventContacts: eventContactsReducer,
  eventTickets: eventTicketsReducer,
  eventFinances: eventFinancesReducer,
  eventDocuments: eventDocumentsReducer,

  tasks: tasksReducer,

  releases: releasesReducer,
  releaseDetail: releaseDetailReducer,
  releaseInfo: releaseInfoReducer,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
