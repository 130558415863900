import * as allConst from "../../../constants/eventDetail/tabGuests";

const initialState = {
  reqParams: {
    includes: "guest, ticket, zone",
    fields: "",
    orderBy: "created_at",
    limit: 20,
    page: 1
  },
  params: {
    guest_id: null,
    isOpenModal: false
  },
  guest: {},
  meta: {pagination:{}},
  guests: [],
  guest_type: [],
  ticket_type: [],
  zone_type: [],
};

const eventGuestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_GUESTS_FIELD:
      let guests = [].concat(state.guests);
      guests[action.id][action.field] = action.payload;
      return {
        ...state,
        guests: guests
      }
    case allConst.SET_GUESTS_VALUE:
      let guest = [].concat(state.guests);
      guest[action.field] = action.payload;
      return {
        ...state,
        guests: guest
      }
    case allConst.SET_GUEST_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventGuestsReducer;
