export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export const textSize = (text, size) => {
  const stText = String(text) || '';
  const sizeInt = size ? parseInt(size) : stText.length + 1

  if (stText.length > sizeInt + 3) {
    return stText.trim().substr(0, sizeInt) + '...';
  }
  return text
}

export const dateFormat = (date, format) => {
  if (date) {
    const dateFormatLib = require('dateformat');
    const newDate = new Date(date);
    return dateFormatLib(newDate, format || "dd.mm.yyyy")
  }
}

// add prefix M if the number is more than a million
export const numbFormat = numb => {
  if (Number.isInteger(numb) && numb.toString().length > 6) {
    return (numb / 1000000).toFixed(1) + "M"
  } else {
    return parseInt(numb).toLocaleString('ru')
  }
}

// Comparison of simple objects
export const deepCompare2Object = (x, y) => {
  if (x && y) {
    return JSON.stringify(x) === JSON.stringify(y)
  } else {
    return false
  }
}

// Date range, start end
export const reqParamsData = countDay => {
  const date = new Date();
  date.setDate(date.getDate() - parseInt(countDay))
  return {
    "to": unixTime(),
    "from": unixTime(date)
  }
}

// Date range for percent
export const reqParamsPercentData = (countDay, customDate) => {
  let customDateParse = parseUnixTime(customDate)
  customDateParse.setDate(customDateParse.getDate() - parseInt(countDay))

  return {"from": unixTime(customDateParse)}
}

//What percentage is the number m of the number n
export const percentage = (m, n) => {
  let mInt = parseInt(m);
  let nInt = parseInt(n);

  if (isNaN(mInt) || isNaN(nInt)) {
    return 0
  } else {
    return ((mInt / nInt) * 100).toFixed(2);
  }
}

//Get unix time
export const unixTime = date => (
  date ? parseInt(new Date(date).getTime() / 1000) : parseInt(new Date().getTime() / 1000)
)

//Parse unix time
export const parseUnixTime = date => {
  if (date === undefined)
    return ''
  if (date) {
    return new Date(date * 1000)
  } else {
    console.error("Empty date")
    return ''
  }
}

export const parseLocationData = data => {
  let newData = data
  if (data && data.geometry) {
    const properties = data.properties
    newData = {
      name: properties.name,
      description: properties.description,
      coordinates: data.geometry.coordinates,
      boundedBy: properties.boundedBy,
      url: properties.CompanyMetaData && properties.CompanyMetaData.url ? properties.CompanyMetaData.url : '',
      phone: properties.CompanyMetaData && properties.CompanyMetaData.Phones ? properties.CompanyMetaData.Phones[0].formatted : '',
    }
  }

  return newData
}


//Old utils

export const getDayAndMonth = date => {
  const newDate = new Date(date);
  const monthRus = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
  return `${newDate.getDate()} ${monthRus[newDate.getMonth()]}`
};

export const getHoursAndMinutes = (date, time) => {
  const newDate = new Date(`${date} ${time}`);
  return `${newDate.getHours()}:${newDate.getMinutes() < 10 ? '0' : ''}${newDate.getMinutes()}`
};
export const dateFormatYYYYMMDD = date => {
  return `${date.getFullYear()}-${parseInt(date.getMonth()) + 1}-${date.getDate()}`
};
export const dateFormatDDMMYYYY = (date, delimiter) => {
  const newDate = new Date(date);
  const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  const month = (parseInt(newDate.getMonth()) + 1) < 10 ? `0${parseInt(newDate.getMonth()) + 1}` : parseInt(newDate.getMonth()) + 1;
  return `${day}${delimiter || '/'}${month}${delimiter || '/'}${newDate.getFullYear()}`
};
export const timeFormatHHMM = time => {
  return `${time.getHours()}:${time.getMinutes()}`
};
export const formatDate = e => {
  let date = new Date(e);
  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();
  return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
};
export const formatTime = e => {
  let date = new Date(e);
  let h = date.getHours();
  let m = date.getMinutes();
  return (h <= 9 ? '0' + h : h) + ':' + (m <= 9 ? '0' + m : m) + ':00';
};
export const onlyNumbersKeypress = e => {
  if (!/\d/.test(e.key))
    e.preventDefault();
};

export const userDATA = () => {
  return JSON.parse(localStorage.getItem('userData')) || {}
};

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
