import themeConfig from "../../../configs/themeConfig"

const initialState = {
  ...themeConfig
}
let data = {};

const setLocalStorage = (key, config) => {
  data = JSON.parse(localStorage.getItem('themeConfig'));
  if (data){
    data[key] = config
  }else{
    data = {}
    data[key] = config
  }
  localStorage.setItem('themeConfig', JSON.stringify(data))
}

const customizerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_CONFIG":
      return {
        ...state,
        theme: action.config.theme,
        sidebarCollapsed: action.config.sidebarCollapsed,
        navbarColor: action.config.navbarColor,
        navbarType: action.config.navbarType,
        footerType: action.config.footerType,
        menuTheme: action.config.menuTheme,
        hideScrollToTop: action.config.hideScrollToTop,
        notifications: action.config.notifications,
      }
    case "CHANGE_MODE":
      setLocalStorage('theme', action.mode)
      return {...state, theme: action.mode}
    case "COLLAPSE_SIDEBAR":
      setLocalStorage('sidebarCollapsed', action.value)
      return {...state, sidebarCollapsed: action.value}
    case "CHANGE_NAVBAR_COLOR":
      setLocalStorage('navbarColor', action.color)
      return {...state, navbarColor: action.color}
    case "CHANGE_NAVBAR_TYPE":
      setLocalStorage('navbarType', action.style)
      return {...state, navbarType: action.style}
    case "CHANGE_FOOTER_TYPE":
      setLocalStorage('footerType', action.style)
      return {...state, footerType: action.style}
    case "CHANGE_MENU_COLOR":
      setLocalStorage('menuTheme', action.style)
      return {...state, menuTheme: action.style}
    case "HIDE_SCROLL_TO_TOP":
      setLocalStorage('hideScrollToTop', action.value)
      return {...state, hideScrollToTop: action.value}
    case "HIDE_NOTIFICATIONS":
      setLocalStorage('notifications', action.value)
      return {...state, notifications: action.value}
    default:
      return state
  }
}

export default customizerReducer
