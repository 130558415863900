import * as allConst from '../../constants/releases'
import {RELEASE_SIDEBAR_HEIGHT} from "../../constants/releases";

const initialState = {
  releases: {},
  modal: false,
  userFilterData: {
    includes: "project,type",
    fields: "",
    orderBy: "created_at",
    limit: 8,
    page: 1,
    projects: '',
    types: '',
    contacts: '',
    tags: '',
    search: '',
    from: '',
    to: '',
  },
  modalData: {
    project_id: "",
    name: 'НАЗВАНИЕ РЕЛИЗА'
  },
  releasesTypes: {
    data: [],
    meta: {}
  },
  sidebarHeight: ''
}

const releasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_RELEASES_PAGINATION:
      return {
        ...state,
        userFilterData: {
          ...state.userFilterData,
          page: action.payload
        }
      };
    case allConst.RELEASE_MODAL_FIELD: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [action.field]: action.payload
        }
      }
    }
    case allConst.RELEASES_MODAL_STATUS: {
      return {
        ...state,
        modal: action.payload
      }
    }
    case allConst.RELEASES_TYPES: {
      return {
        ...state,
        releasesTypes: {...action.payload}
      }
    }
    case allConst.SET_RELEASE_FILTER_CHECKED:
      return {
        ...state,
        userFilterData: {
          ...state.userFilterData,
          [action.field]: action.payload,
          page: 1
        }
      };
    case allConst.RELEASE_SIDEBAR_HEIGHT: {
      return {
        ...state,
        sidebarHeight: action.payload
      }
    }
    case allConst.CLEAR_RELEASES_MODAL: {
      return {
        ...state,
        modalData: {
          project_id: "",
          name: 'НАЗВАНИЕ РЕЛИЗА'
        }
      }
    }
    case allConst.CLEAR_RELEASES_FILTER:
      return {
        ...state,
        userFilterData: {
          includes: "",
          fields: "",
          orderBy: "created_at",
          limit: 8,
          page: 1,
          projects: '',
          types: '',
          contacts: '',
          tags: '',
          search: '',
          from: '',
          to: '',
        },
      }
    case allConst.SET_RELEASES:
      return {
        ...state,
        releases: {...action.payload}
      }
    default:
      return state
  }
}

export default releasesReducer
