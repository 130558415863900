import * as allConst from "../../../constants/eventDetail/tabInfo";
import {CLEAR_EVENT_INFO, SET_EVENT_INFO_VALUE} from "../../../constants/eventDetail/tabInfo";
import {now} from "moment";

const initialState = {
  reqParams: {
    includes: "project, location, type, tickets, tags, project.owner",
    fields: ""
  },
  types: [],
  params: {
    isUpdate: false,
    isTagsModal: false
  },
  event: {
    id: null,
    name: 'Название',
    description: null,
    begin: Math.round((new Date()).getTime() / 1000),
    end: Math.round((new Date()).getTime() / 1000),
    guarantee: null,
    percent: null,
    earning_type: 0,
    capacity: null,
    creates_at: null,
    updated_at: null,
    project: {
      name: 'неизвестно'
    },
    location: {
      name: 'неизвестно'
    },
    tags: [],
    type: {},
    tickets: []
  }
};

const eventInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_EVENT_INFO: {
      return {
        ...state,
        event: action.payload
      }
    }
    case allConst.SET_EVENT_INFO_FIELD:
      return {
        ...state,
        event: {
          ...state.event,
          [action.field]: action.payload,
        },
      }
    case allConst.SET_EVENT_INFO_VALUE:
      return {
        ...state,
        [action.field]: action.payload,
      }
    case allConst.SET_EVENT_INFO_TYPES: {
      return {
        ...state,
        types: action.payload
      }
    }
    case allConst.CLEAR_EVENT_INFO: {
      return {
        ...state,
        event: {
          id: null,
          name: 'Название',
          description: null,
          begin: Math.round((new Date()).getTime() / 1000),
          end: Math.round((new Date()).getTime() / 1000),
          guarantee: null,
          percent: null,
          earning_type: 0,
          capacity: null,
          creates_at: null,
          updated_at: null,
          project: {
            name: 'неизвестно'
          },
          location: {
            name: 'неизвестно'
          },
          tags: [],
          type: {},
          tickets: []
        }
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventInfoReducer;
