const initialState = {

}

const eventDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state
      }
  }
}

export default eventDetailReducer
