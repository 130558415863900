import {history} from "../../../history"
import {CHANGE_ROLE} from '../../constants/auth';
import {makeLogin, makeLogout, makeRegister, resetAuthData} from '../../../services/auth/auth.service';
import {setSpinner, unsetSpinner} from "../helper";
import {SET_USER_DATA} from "../../constants/users";
import {RESET_STATE} from "../../constants/helper";

export const changeRole = role => {
  return dispatch => dispatch({type: CHANGE_ROLE, userRole: role})
}

export const loginUser = (email, password) => {
  return dispatch => {
    dispatch(setSpinner('login-form'))
    makeLogin({email, password})
      .then((response) => {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            ...response.me,
            verify_id: response.me.id,
            me_id: null,
            role: response.me.role.name
          }
        })
        history.push("/")
      })
      .finally(() => {
        dispatch(unsetSpinner('login-form'))
      })
  }
}

export const logoutUser = () => {
  return dispatch => {
    makeLogout()
      .then()
      .catch()
      .finally(() => {
        resetAuthData()
        dispatch({type: RESET_STATE})
        history.push('/login')
      })
  }
}

export const registerUser = (name, email, password) => {
  return dispatch => {
    dispatch(setSpinner('register-form'))
    makeRegister({name, email, password})
      .then((response) => {
        dispatch({
          type: SET_USER_DATA,
          payload: {
            ...response.me.data,
            verify_id: response.me.id,
            me_id: null,
            role: response.me.role.name
          }
        })
        history.push("/")
      })
      .catch()
      .finally(() => {
        dispatch(unsetSpinner('register-form'))
      })
  }
}
