export const SET_RELEASE_INFO = 'SET_RELEASE_INFO'
export const SET_RELEASE_INFO_TYPES = 'SET_RELEASE_INFO_TYPES'
export const SET_RELEASE_INFO_FIELD = 'SET_RELEASE_INFO_FIELD'
export const SET_RELEASE_INFO_VALUE = 'SET_RELEASE_INFO_VALUE'
export const CLEAR_RELEASE_INFO = 'CLEAR_RELEASE_INFO'
export const SET_RELEASE_INFO_GENRES = 'SET_RELEASE_INFO_GENRES'
export const SET_RELEASE_INFO_SUBGENRES = 'SET_RELEASE_INFO_SUBGENRES'
export const RELEASE_FORM_ADD_TRACK_ELEMENT = 'RELEASE_FORM_ADD_TRACK_ELEMENT'
export const RELEASE_FORM_DELETE_TRACK_ELEMENT = 'RELEASE_FORM_DELETE_TRACK_ELEMENT'
export const RELEASE_FORM_SET_TRACK_ELEMENT = 'RELEASE_FORM_SET_TRACK_ELEMENT'
export const RELEASE_FORM_SET_TRACK_ID_ELEMENT = 'RELEASE_FORM_SET_TRACK_ID_ELEMENT'
export const RELEASE_FORM_ADD_TRACK = 'RELEASE_FORM_ADD_TRACK'
export const RELEASE_FORM_DELETE_TRACK = 'RELEASE_FORM_DELETE_TRACK'
