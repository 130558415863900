import * as allConst from '../../constants/analytics'
import { reqParamsData } from '../../../helpers/utils'

const initialState = {
  analyticsRequestParams: {
    "fields": "analytics.likes,analytics.reposts,analytics.comments,analytics.views ",
    "limit": 30,
    new: true,
    ...reqParamsData(30)
  },
  statisticsRequestParams: {
    "limit": 30,
    "fields": "statistics.key, statistics.value"
  },
  followersRequestParams: {
    "limit": 30,
    ...reqParamsData(30)
  },
  analyticsStatus: false,
  statisticsStatus: false,
  selectedCountDay: { value: 30, label: '30 дней' },
  statistics: {
    age: [],
    city: [],
    country: [],
    interests: [],
    occupation: [],
    relation: [],
    sex: []
  },
  followers: {
    date: [],
    followers: [],
    newFollowers: '',
    unsubscribed: ''
  },
  spotify: {
    id: '',
    name: '',
    related: [],
    tracks: [],
    img: '',
    popularity: ''
  },
  analytics: '',
  requestParams: '',
  percentData: '',
  group_id: '',
  selectedProject: '',
}

const analyticsReducer = (state = initialState, action) => {
  switch(action.type) {
    case allConst.SET_ANALYTICS: {
      return {
        ...state,
        analytics: {
          ...action.payload
        }

      }
    }

    case allConst.SET_ANALYTICS_REQ_PARAMS: {
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        },
        analyticsRequestParams: {
          ...state.analyticsRequestParams,
          ...action.payload
        },
        statisticsRequestParams: {
          ...state.statisticsRequestParams,
          ...action.payload
        },
        followersRequestParams: {
          ...state.followersRequestParams,
          ...action.payload
        },
      }
    }

    case allConst.SET_SELECTED_COUNT_DAY: {
      return {
        ...state,
        selectedCountDay: {...action.payload}
      }
    }

    case allConst.SET_PERCENT_DATA: {
      return {
        ...state,
        percentData: action.payload
      }
    }

    case allConst.SET_ANALYTICS_STATUS: {
      return {
        ...state,
        analyticsStatus: action.payload
      }
    }

    case allConst.SET_STATISTICS_STATUS: {
      return {
        ...state,
        statisticsStatus: action.payload
      }
    }

    case allConst.SET_STATISTICS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          ...action.payload
        }
      }
    }

    case allConst.SET_FOLLOWERS: {
      return {
        ...state,
        followers: {
          ...state.followers,
          ...action.payload
        }
      }
    }

    case allConst.SET_SPOTIFY: {
      return {
        ...state,
        spotify: {
          ...state.spotify,
          ...action.payload
        }
      }
    }

    case allConst.ANALYTICS_CLEAR_DATA: {
      return {
        ...state,
        group_id: '',
        statistics: {
          age: [],
          city: [],
          country: [],
          interests: [],
          occupation: [],
          relation: [],
          sex: []
        },
        followers: {
          date: [],
          followers: [],
          newFollowers: '',
          unsubscribed: ''
        },
        spotify: {
          id: '',
          name: '',
          related: [],
          tracks: [],
          img: '',
          popularity: ''
        },
        analytics: '',
        percentData: ''
      }
    }
    case allConst.SET_GROUP_ID: {
      return {
        ...state,
        group_id: action.payload
      }
    }
    case allConst.SET_ANALYTICS_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload
      }
    }
    case allConst.CLEAR_ALL_ANALYTICS_STATE: {
      return {
        ...state,
        group_id: '',
        analyticsRequestParams: {
          "fields": "analytics.likes,analytics.reposts,analytics.comments,analytics.views ",
          "limit": 30,
          new: true,
          ...reqParamsData(30)
        },
        statisticsRequestParams: {
          "limit": 30,
          "fields": "statistics.key, statistics.value"
        },
        followersRequestParams: {
          "limit": 30,
          ...reqParamsData(30)
        },
        analyticsStatus: false,
        statisticsStatus: false,
        selectedCountDay: { value: 30, label: '30 дней' },
        statistics: {
          age: [],
          city: [],
          country: [],
          interests: [],
          occupation: [],
          relation: [],
          sex: []
        },
        followers: {
          date: [],
          followers: [],
          newFollowers: '',
          unsubscribed: ''
        },
        spotify: {
          id: '',
          name: '',
          related: [],
          tracks: [],
          img: '',
          popularity: ''
        },
        analytics: '',
        requestParams: '',
        percentData: '',
        selectedProject: '',
      }
    }

    default:
      return state
  }
}

export default analyticsReducer;
