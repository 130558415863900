import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: "projectsTab",
    title: "Проекты",
    type: "collapse",
    icon: <Icon.AlignRight size={20} />,
    children: [
      {
        id: "projects",
        title: "Список проектов",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "manager", "user"],
        navLink: "/",
      }
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "События"
  },
  {
    id: "events",
    title: "Список событий",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/events",
  },
  {
    type: "groupHeader",
    groupTitle: "Релизы"
  },
  {
    id: "releases",
    title: "Список релизов",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/releases",
  },
  {
    type: "groupHeader",
    groupTitle: "Инструменты"
  },
  {
    id: "tasks",
    title: "Задачи",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/tasks",
  },
  {
    id: "contacts",
    title: "Контакты",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/contacts",
  },
  {
    id: "finances",
    title: "Финансы",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/coming",
  },
  {
    id: "base",
    title: "База заведений",
    type: "item",
    icon: <Icon.Database size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/coming",
  },
  {
    id: "merch",
    title: "Мерч",
    type: "item",
    icon: <Icon.ShoppingCart size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/coming",
  },
  {
    type: "groupHeader",
    groupTitle: "Настройки и профиль"
  },
  {
    id: "usersTab",
    title: "Пользователь",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "users",
        title: "Список пользователей",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin"],
        navLink: "/users",
      }
    ]
  },
  {
    id: "support",
    title: "Поддержка",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/coming",
  },
  {
    id: "updates",
    title: "Обновления",
    type: "item",
    icon: <Icon.Rss size={20} />,
    permissions: ["admin", "manager", "user"],
    navLink: "/coming",
  },
]

export default navigationConfig
