import * as allConst from '../../constants/tags'

const initialState = {
  tags: {
    data: [],
    meta: {}
  },
}

const tagsReducer = (state = initialState, action) => {
  switch(action.type) {
    case allConst.SET_TAGS: {
      return {
        ...state,
        tags: {...action.payload}
      }
    }
    default:
      return {
        ...state
      }
  }
}

export default tagsReducer
