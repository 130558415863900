import axios from 'axios'
import * as authService from './auth/auth.service'
import { history } from "../history"

export const URL = 'https://back.arimo.ru/';
const API_URL = 'https://back.arimo.ru/api/';

export default class Http {
  constructor(status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: API_URL,
      headers: {
        'accept' : 'application/x.arimo.v1+json'
      }
    })

    return this.init()
  }

  init() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        request => {
          request.headers['Authorization'] = 'Bearer ' + authService.getAccessToken()
          if (authService.isAccessTokenExpired() /*&& authService.getRefreshToken()*/) {
            return authService.refreshTokens().then((response) => {
                request.headers['Authorization'] = 'Bearer ' + response.token
                return request
            }).catch(() => {
              authService.resetAuthData()
              history.push('/login')
            })
          } /*else if(!authService.getRefreshToken()) {
            store.dispatch(setNotification(true, "Токен не действительный!", "danger"))
            authService.resetAuthData()
            history.push('/login')
            return
          }*/ else {
            return request
          }
        },
        error => {
          return Promise.reject(error)
        }
      )

      return this.instance
    }
    return this.instance
  }
}
