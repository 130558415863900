import * as allConst from "../../../constants/releaseDetail/tabInfo";
import {CLEAR_RELEASE_INFO, SET_RELEASE_INFO_VALUE} from "../../../constants/releaseDetail/tabInfo";
import {now} from "moment";

const initialState = {
  reqParams: {
    includes: "project,type,project.owner,genre,tracks,subgenre,cover",
    fields: ""
  },
  types: [],
  genres: [],
  subgenres: [],
  params: {
    isEdited: false
  },
  release: {
    id: null,
    name: '',
    description: null,
    releases_date: null,
    prereleases_date: null,
    divide: null,
    divider_artists: [],
    budget: null,
    release_link: null,
    entry_year: null,
    cover: {},
    promo_link: null,
    artist_socials: {
      facebook: "",
      youtube: "",
      twitter: "",
      vkontakte: "",
      instagram: "",
      tiktok: "",
    },
    artist_shops: {
      spotify: "",
      itunes: "",
      youtube: "",
      vkontakte: "",
    },
    artist_description: null,
    creates_at: null,
    updated_at: null,
    genre: {},
    subgenre: {},
    project: {
      name: ''
    },
    type: {},
    tracks: []
  }
};

const releaseInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_RELEASE_INFO: {
      return {
        ...state,
        release: action.payload
      }
    }
    case allConst.SET_RELEASE_INFO_FIELD:
      return {
        ...state,
        release: {
          ...state.release,
          [action.field]: action.payload,
        },
      }
    case allConst.SET_RELEASE_INFO_VALUE:
      return {
        ...state,
        [action.field]: action.payload,
      }
    case allConst.SET_RELEASE_INFO_TYPES: {
      return {
        ...state,
        types: action.payload
      }
    }
    case allConst.SET_RELEASE_INFO_GENRES: {
      return {
        ...state,
        genres: action.payload
      }
    }
    case allConst.SET_RELEASE_INFO_SUBGENRES: {
      return {
        ...state,
        subgenres: action.payload
      }
    }
    case allConst.RELEASE_FORM_ADD_TRACK_ELEMENT: {
      const tracks = [].concat(state.release.tracks);
      if (!Array.isArray(tracks[action.id][action.field])) tracks[action.id][action.field] = []
      tracks[action.id][action.field].push(action.payload)
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.RELEASE_FORM_DELETE_TRACK_ELEMENT: {
      const tracks = [].concat(state.release.tracks);
      if (!Array.isArray(tracks[action.id][action.field])) tracks[action.id][action.field] = []
      tracks[action.id][action.payload].splice(action.field, 1)
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.RELEASE_FORM_SET_TRACK_ELEMENT: {
      const tracks = [].concat(state.release.tracks);
      tracks[action.id][action.field] = action.payload
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.RELEASE_FORM_SET_TRACK_ID_ELEMENT: {
      const tracks = [].concat(state.release.tracks);
      const length = tracks[action.id][action.field].length
      if (length === 0)
        tracks[action.id][action.field].push(action.payload)
      else
        tracks[action.id][action.field][length - 1] = action.payload
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.RELEASE_FORM_ADD_TRACK: {
      const tracks = [].concat(state.release.tracks);
      tracks.push({
        name: 'Новая композиция',
        track_composer: [],
        text_composer: [],
        together_with: [],
      })
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.RELEASE_FORM_DELETE_TRACK: {
      const tracks = [].concat(state.release.tracks);
      tracks.splice(action.id, 1)
      return {
        ...state,
        release: {
          ...state.release,
          tracks: tracks
        }
      }
    }
    case allConst.CLEAR_RELEASE_INFO: {
      return {
        ...state,
        release: {
          id: null,
          name: '',
          description: null,
          releases_date: null,
          prereleases_date: null,
          divide: null,
          divider_artists: [],
          budget: null,
          release_link: null,
          entry_year: null,
          cover: {},
          promo_link: null,
          artist_socials: {
            facebook: "",
            youtube: "",
            twitter: "",
            vkontakte: "",
            instagram: "",
            tiktok: "",
          },
          artist_shops: {
            spotify: "",
            itunes: "",
            youtube: "",
            vkontakte: "",
          },
          artist_description: null,
          creates_at: null,
          updated_at: null,
          genre: {},
          subgenre: {},
          project: {
            name: ''
          },
          type: {},
          tracks: []
        }
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default releaseInfoReducer;
