import {BaseService} from './base.service'
import Wrapper from "./util";
import {getUserData, getVerifyId} from "./auth/auth.service";

export class UsersService extends BaseService {
  static get entity() {
    return 'users'
  }

  static SwitchToAnotherUser(id, isShowNotification = true) {
    let wrapper = new Wrapper;
    return new Promise((resolve, reject) => {
      return this.request({auth: true}).get(`${this.entity}/${id}`).then(response => {
        wrapper.setWrapper(response, isShowNotification).then(() => {
          _setAuthData(id, response.data)
          return resolve(response.data)
        }).catch((e) => {
          return reject(response.data)
        })
      }).catch(error => {
        reject(console.log('getById ' + this.entity, error))
      })
    })
  }
}

export function _setAuthData(id, response) {
  let me_id;
  if(getUserData().me_id === id){
    me_id = null
  } else if (getUserData().me_id === undefined || getUserData().me_id === null) {
    me_id = getVerifyId()
  }else{
    me_id = getUserData().me_id
  }
  localStorage.setItem('userData', JSON.stringify({
    ...response.data.user,
    verify_id: response.data.user.id,
    me_id: me_id,
    role: response.data.user.role.name
  }))
}
