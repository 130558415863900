export const SET_PROJECT_REQUEST_PARAMS = 'SET_PROJECT_REQUEST_PARAMS'
export const CLEAR_PROJECT_ALL_STATE = 'CLEAR_PROJECT_ALL_STATE'
export const SET_OWNER_PROJECT_LIST = 'SET_OWNER_PROJECT_LIST'
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST'
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA'
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS'
export const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE'
export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const SET_MODAL_DATA = 'SET_MODAL_DATA'
export const CLEAR_FORM = 'CLEAR_FORM'
export const SET_SPOTIFY_LIST = 'SET_SPOTIFY_LIST'
