import {setNotification} from '../redux/actions/helper'
import React from 'react';
import {store} from "../redux/storeConfig/store"
import {history} from "../history";
import {resetAuthData} from "./auth/auth.service";

export default class Wrapper {
  setWrapper(response, isShowNotification = true) {
    return new Promise((resolve, reject) => {
      if (!response.data.error) {
        /*if (isShowNotification)
          this.showSuccessMessage(response.data.data.message)*/
        return resolve(response)
      } else {
        switch (response.data.error.status_code) {
          case 401: {
            this.showErrorMessage(response.data.error.message)
            resetAuthData()
            history.push("/login")
            return reject(401, response)
          }
          default:
            this.showErrorMessage(response.data.error.message)
            return reject(null, response)
        }
      }
    })
  }

  showErrorMessage(message) {
    store.dispatch(setNotification(message, "error"))
  }

  showSuccessMessage(message) {
    store.dispatch(setNotification(message, "success"))
  }
}
