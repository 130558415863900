import {setSpinner, unsetSpinner} from "../helper";
import {UsersService} from "../../../services/users.service";
import {SET_USER_DATA, SET_USERS_LIST, SET_USERS_REQUEST_PARAMS} from "../../constants/users";
import {RESET_STATE} from "../../constants/helper";
import window from "auth0-js/src/helper/window";
import {getUserData, getVerifyId} from "../../../services/auth/auth.service";
import {logoutUser} from "../auth/loginActions";

export const getUserList = (requestParams) => {
    return dispatch => {
        dispatch(setSpinner('users-list'))
        UsersService.index(requestParams).then(response => {
            dispatch({
                type: SET_USERS_LIST,
                payload: response.data.users
            })
        }).finally(() => {
            dispatch(unsetSpinner('users-list'))
        })
    }
}

export const SwitchToAnotherUser = (id, fromLogout = false) => {
    return dispatch => {
        if (id !== null) {
            dispatch(setSpinner('users-list'))
            UsersService.SwitchToAnotherUser(id).then(response => {
                dispatch({type: RESET_STATE})
                let me_id;
                if (getUserData().me_id === id) {
                    me_id = null
                } else if (getUserData().me_id === undefined || getUserData().me_id === null) {
                    me_id = getVerifyId()
                } else {
                    me_id = getUserData().me_id
                }
                dispatch({
                    type: SET_USER_DATA,
                    payload: {
                        ...response.data.user,
                        verify_id: response.data.user.id,
                        me_id: me_id,
                        role: response.data.user.role.name
                    }
                })
                window.getWindow().location.reload()
            })
                .catch(() => {
                    if (fromLogout){
                        dispatch(logoutUser())
                    }
                })
                .finally(() => {
                    dispatch(unsetSpinner('users-list'))
                })
        }
    }
}

export const changeUserData = payload => {
    return dispatch => dispatch({type: SET_USER_DATA, payload})
}

export const setRequestParams = data => {
    return {
        type: SET_USERS_REQUEST_PARAMS,
        payload: data
    }
}
