import * as allConst from "../../constants/tasks";

const initialState = {
  reqParams: {
    includes: "tags, files",
    fields: "",
    orderBy: "created_at",
    limit: 12,
    page: 1,
    filter: 'all',
    tags: [],
    project_id: null,
    event_id: null
  },
  params: {
    isOpenModal: false,
    task_id: 8,
    isTagsOpen: false,
    isTagsModal: false
  },
  tasks: [],
  task: {
    tags: [],
    files: {},
    name: ''
  },
  meta: {pagination: {}},
};

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_TASKS_FIELD:
      let tasks = [].concat(state.tasks);
      tasks[action.id][action.field] = action.payload;
      return {
        ...state,
        tasks: tasks
      }
    case allConst.SET_TASKS_VALUE:
      let task = [].concat(state.tasks);
      task[action.field] = action.payload;
      return {
        ...state,
        tasks: task
      }
    case allConst.SET_TASK_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    case allConst.SET_TASK_FILE: {
      return {
        ...state,
        task: {
          ...state.task,
          files: action.payload
        }
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default tasksReducer;
