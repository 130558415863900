import * as allConst from "../../../constants/eventDetail/tabTickets";

const initialState = {
  reqParams: {
    includes: "type",
    fields: "",
    orderBy: "created_at",
    limit: 20,
    page: 1
  },
  params: {
    ticket_id: null,
    isOpenModal: false
  },
  ticket: {},
  meta: {pagination:{}},
  tickets: [],
  types: [],
};

const eventTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_TICKETS_FIELD:
      //console.log(action.id,action.field,action.payload)
      let tickets = [].concat(state.tickets);
      tickets[action.id][action.field] = action.payload;
      return {
        ...state,
        tickets: tickets
      }
    case allConst.SET_TICKETS_VALUE:
      let ticket = [].concat(state.tickets);
      ticket[action.field] = action.payload;
      return {
        ...state,
        tickets: ticket
      }
    case allConst.SET_TICKET_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventTicketsReducer;
