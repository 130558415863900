import {SET_NOTIFICATION, UNSET_NOTIFICATION, SET_SPINNER, SET_HELPER, UNSET_SPINNER} from '../../constants/helper';

const initialState = {
  notifications: [],
  spinner: [],
  verify_id: null
}

const helperReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: {
      let data = [...state.notifications];
      data.push({
        id: Math.random().toString(36).substring(7),
        message: action.payload.message,
        status: action.payload.status,
      })
      return {
        ...state,
        notifications: data
      }
    }
    case UNSET_NOTIFICATION: {
      let data = [...state.notifications].filter((value) => {
        return !(value.id === action.payload.id)
      })
      return {
        ...state,
        notifications: data
      }
    }
    case SET_SPINNER: {
      let data = [...state.spinner];
      data.push(action.payload.id)

      return {
        ...state,
        spinner: data
      }
    }
    case UNSET_SPINNER: {
      let data = [...state.spinner];
      let id = state.spinner.indexOf(action.payload.id)

      if (id !== -1) {
        data.splice(id, 1);
      }

      return {
        ...state,
        spinner: data
      }
    }
    case SET_HELPER: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }

    default:
      return state
  }
}

export default helperReducer;
