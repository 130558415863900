import {SET_NOTIFICATION, UNSET_NOTIFICATION, SET_SPINNER, UNSET_SPINNER} from '../../constants/helper';

export const setNotification = (message, status) => {
  return {
    type: SET_NOTIFICATION,
    payload: {
      message,
      status
    }
  }
}
export const unsetNotification = (id) => {
  return {
    type: UNSET_NOTIFICATION,
    payload: {
      id
    }
  }
}
export const setSpinner = (id = '') => {
  return {
    type: SET_SPINNER,
    payload: {
      id
    }
  }
}

export const unsetSpinner = (id = '') => {
  return {
    type: UNSET_SPINNER,
    payload: {
      id
    }
  }
}

export const setData = (type, payload) => {
  return {
    type,
    payload
  }
}

export const setFieldData = (type, field, payload) => {
  return {
    type,
    field,
    payload
  }
}
export const setFieldIdData = (type, field, payload, id) => {
  return {
    type,
    field,
    payload,
    id
  }
}
