import * as allConst from "../../../constants/eventDetail/tabFinances";

const initialState = {
  reqParams: {
    includes: "",
    fields: "",
    orderBy: "created_at",
  },
  finance: {},
  finances: [],
};

const eventFinancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_FINANCES_FIELD:
      let finances = [].concat(state.finances);
      finances[action.id][action.field] = action.payload;
      return {
        ...state,
        finances: finances
      }
    case allConst.SET_FINANCES_VALUE:
      let finance = [].concat(state.finances);
      finance[action.field] = action.payload;
      return {
        ...state,
        finances: finance
      }
    case allConst.SET_FINANCE_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    default:
      return {
        ...state
      }
  }
};

export default eventFinancesReducer;
