export const SET_EVENTS = 'SET_EVENTS';
export const EVENTS_TYPES = "EVENTS_TYPES"
export const SIDEBAR_HEIGHT = 'SIDEBAR_HEIGHT'
export const EVENT_MODAL_FIELD = "EVENT_MODAL_FIELD"
export const CLEAR_EVENTS_MODAL = "CLEAR_EVENTS_MODAL"
export const EVENTS_MODAL_STATUS = "EVENTS_MODAL_STATUS"
export const CLEAR_EVENTS_FILTER = "CLEAR_EVENTS_FILTER"
export const SET_EVENTS_PAGINATION = 'SET_EVENTS_PAGINATION'
export const EVENTS_MODAL_LOCATIONS = "EVENTS_MODAL_LOCATIONS"
export const CLEAR_ALL_EVENTS_STATE = 'CLEAR_ALL_EVENTS_STATE'
export const SET_EVENT_FILTER_CHECKED = 'SET_EVENT_FILTER_CHECKED'
