import * as allConst from '../../constants/projects'

const initialState = {
  list: [],
  meta: {},
  ownerProjectList: [],
  ownerProjectMeta: {},
  requestParams: {
    "page": 1,
    "includes" : "group,spotify,owner",
    "fields" : "group.link,group.id",
    "orderBy": "-id",
    "limit": 9
  },
  modalData: {
    name: "",
    link: "",
    spotify: {
      artistId: null,
      name: ''
    },
    description: "",
  },
  spotify_list: [],
  modal: false,
  requestType: "update",
  projectId: '',
}

const projectsReducer = (state = initialState, action) => {
  switch(action.type) {
    case allConst.SET_PROJECT_LIST: {
      return {
        ...state,
        list: action.payload.data,
        meta: action.payload.meta
      }
    }
    case allConst.SET_OWNER_PROJECT_LIST: {
      return {
        ...state,
        ownerProjectList: action.payload.data,
        ownerProjectMeta: action.payload.meta
      }
    }
    case allConst.SET_PROJECT_REQUEST_PARAMS: {
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload
        }
      }
    }
    case allConst.SET_MODAL_STATUS: {
      return {
        ...state,
        modal: action.payload
      }
    }
    case allConst.SET_SPOTIFY_LIST: {
      return {
        ...state,
        spotify_list: action.payload
      }
    }
    case allConst.SET_REQUEST_TYPE: {
      return {
        ...state,
        requestType: action.payload
      }
    }
    case allConst.SET_PROJECT_DATA: {
      return {
        ...state,
        modalData: action.payload
      }
    }
    case allConst.SET_MODAL_DATA: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          ...action.payload
        }
      }
    }
    case allConst.SET_PROJECT_ID: {
      return {
        ...state,
        projectId: action.payload
      }
    }
    case allConst.CLEAR_FORM: {
      return {
        ...state,
        modalData: {
          name: "",
          link: "",
          description: "",
          spotify: {
            artistId: null,
            name: ''
          },
        }
      }
    }
    case allConst.CLEAR_PROJECT_ALL_STATE: {
      return {
        ...state,
        list: [],
        meta: {},
        ownerProjectList: [],
        ownerProjectMeta: {},
        requestParams: {
          "page": 1,
          "includes" : "group,spotify,owner",
          "fields" : "group.link,group.id",
          "orderBy": "-id",
          "limit": 9
        },
        modalData: {
          name: "",
          link: "",
          spotify: {
            artistId: null,
            name: ''
          },
          description: "",
        },
        spotify_list: [],
        modal: false,
        requestType: "update",
        projectId: '',
      }
    }

    default:
      return state
  }
}

export default projectsReducer;
