import * as allConst from '../../constants/events'

const initialState = {
  modalData: {
    project_id: "",
    name: "",
    type_id: "",
    locations: "",
    dateStart: "",
    dateEnd: "",
    timeStart: "",
    timeEnd: ""
  },
  userFilterData: {
    includes: "tags,location",
    fields: "type.name,tags.name,tags.color,tags.id,location.name",
    orderBy: "id",
    limit: 8,
    page: 1,
    projects: '',
    types: '',
    contacts: '',
    tags: '',
    search: '',
    from: '',
    to: '',
  },
  locationsData: "",
  modal: false,
  eventsTypes: {
    data: [],
    meta: {}
  },
  earningTypes: [
    {value: 0, label: 'Другое', key:''},
    {value: 1, label: 'Процент', key:'percent'},
    {value: 2, label: 'Гонорар', key:'guarantee'},
  ],
  events: {},
  sidebarHeight: ''
}

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.EVENTS_MODAL_STATUS: {
      return {
        ...state,
        modal: action.payload
      }
    }
    case allConst.EVENTS_MODAL_LOCATIONS: {
      return {
        ...state,
        locationsData: action.payload
      }
    }
    case allConst.EVENT_MODAL_FIELD: {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [action.field]: action.payload
        }
      }
    }
    case allConst.EVENTS_TYPES: {
      return {
        ...state,
        eventsTypes: {...action.payload}
      }
    }
    case allConst.CLEAR_EVENTS_MODAL: {
      return {
        ...state,
        modalData: {
          project_id: "",
          name: "",
          type_id: "",
          location_id: "",
          dateStart: "",
          dateEnd: "",
          timeStart: "",
          timeEnd: ""
        },
        locationsData: ""
      }
    }
    case allConst.SET_EVENT_FILTER_CHECKED:
      return {
        ...state,
        userFilterData: {
          ...state.userFilterData,
          [action.field]: action.payload,
          page: 1
        }
      };
    case allConst.SET_EVENTS_PAGINATION:
      return {
        ...state,
        userFilterData: {
          ...state.userFilterData,
          page: action.payload
        }
      };
    case allConst.SET_EVENTS:
      return {
        ...state,
        events: {...action.payload}
      }
    case allConst.CLEAR_EVENTS_FILTER:
      return {
        ...state,
        userFilterData: {
          includes: "tags,location",
          fields: "type.name,tags.name,tags.color,tags.id,location.name",
          orderBy: "id",
          limit: 8,
          page: 1,
          projects: '',
          types: '',
          contacts: '',
          tags: '',
          search: '',
          from: '',
          to: '',
        },
      }
    case allConst.SIDEBAR_HEIGHT: {
      return {
        ...state,
        sidebarHeight: action.payload
      }
    }
    case allConst.CLEAR_ALL_EVENTS_STATE:
      return {
        ...state,
        modalData: {
          project_id: "",
          name: "",
          type_id: "",
          locations: "",
          dateStart: "",
          dateEnd: "",
          timeStart: "",
          timeEnd: ""
        },
        userFilterData: {
          includes: "tags,location",
          fields: "type.name,tags.name,tags.color,tags.id,location.name",
          orderBy: "id",
          limit: 8,
          page: 1,
          projects: '',
          types: '',
          contacts: '',
          tags: '',
          search: '',
          from: '',
          to: '',
        },
        locationsData: "",
        modal: false,
        eventsTypes: {
          data: [],
          meta: {}
        },
        events: {},
        sidebarHeight: ''
      }
    default:
      return state
  }
}

export default eventsReducer
