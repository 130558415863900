import * as allConst from '../../constants/contacts'

const initialState = {
  contact: {
    name: '',
    phone: '',
    email: '',
    type: {
      id: null,
      name: '',
      data: {}
    },
    data: []
  },
  types: [],
  contacts: [],
  reqParams: {
    include: 'type',
    orderBy: "created_at",
    type: -1,
    status: 0,
    name: '',
    email: '',
  },
  meta: {pagination:{}},
  params: {
    isOpenModal: false,
    contact_id: null,
  }
}

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case allConst.SET_CONTACTS: {
      return {
        ...state,
        contacts: {...action.payload}
      }
    }
    case allConst.SET_CONTACTS_VALUE: {
      let contact = [].concat(state.contacts);
      contact[action.field] = action.payload;
      return {
        ...state,
        contacts: contact
      }
    }
    case allConst.SET_CONTACTS_DATA_VALUE: {
      let data = [].concat(state.contact.data);
      data[action.id].value = action.payload;
      return {
        ...state,
        contact: {
          ...state.contact,
          data: data
        }

      }
    }
    case allConst.SET_CONTACT_FIELD: {
      return {
        ...state,
        [action.field]: action.payload
      }
    }
    case allConst.UNSET_CONTACT_FIELD: {
      return {
        ...state,
        contact: {
          name: '',
          phone: '',
          email: '',
          type: {
            id: null,
            name: '',
            data: {}
          },
          data: []
        }
      }
    }
    default:
      return {
        ...state
      }
  }
}

export default contactsReducer
